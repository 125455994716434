<template>
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.header_title || thePage.contents.header_img"
      name="PageHeader"
      :bg-image="thePage.contents.header_img"
      :title="thePage.contents.header_title"
    />

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_1_title ||
          thePage.contents.ns_1_description ||
          thePage.contents.ns_1_img
      "
      name="PageSectionNarrative"
      section-id="1"
      class="white--text pt-5"
      :bg-color="thePage.contents.ns_1_bg_color"
      :title-class="thePage.contents.ns_1_title_class"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_1_description"
            tag="p"
            class="r-headline"
          />
          <component
            :is="
              thePage.contents.ns_1_btn_outline_color
                ? 'BaseButtonOutline'
                : 'BaseButton'
            "
            v-if="thePage.contents.ns_1_btn_text"
            :outline-color="thePage.contents.ns_1_btn_outline_color"
            :color="thePage.contents.ns_1_btn_color"
            :text-color="thePage.contents.ns_1_btn_text_color"
            class="ml-0"
            :to="thePage.contents.ns_1_btn_route"
            :href="thePage.contents.ns_1_btn_link"
          >
            {{ thePage.contents.ns_1_btn_text }}
          </component>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.show_other_mentions"
      :press-items="otherMentions"
      title="Featured Press"
      name="OtherMentions"
    />
    <BaseThemeComponent name="CateringCTA" />
    <BaseThemeComponent name="SectionLocations" />
  </div>
</template>

<script>
import PageMixin from '@mixins/page'
import _get from 'lodash/get'
export default {
  name: 'BubsAboutAndPressPage',
  mixins: [PageMixin],
  data() {
    return {}
  },
  computed: {
    pressItems() {
      let items = _get(this.thePage, 'contents.press_items', [])
      let r
      try {
        r = JSON.parse(items)
      } catch (error) {
        r = items
      }
      return r
    },
    otherMentions() {
      let items = _get(this.thePage, 'contents.other_mentions', [])
      let r
      try {
        r = JSON.parse(items)
      } catch (error) {
        r = items
      }
      return r
    },
  },
}
</script>
